import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Divider, Button, Modal, Avatar } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import FaceScan from '../FaceScan/FaceScan';
import CreateWallet from '../Wallet/CreateWallet';
import WalletOptions from '../Wallet/WalletOptions'; // ✅ Import the new WalletOptions component
import { useAuth } from '../../context/AuthContext';
import { useSubdomain } from '../../utils/useSubdomain';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import FaceRetouchingNaturalRoundedIcon from '@mui/icons-material/FaceRetouchingNaturalRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import LanRoundedIcon from '@mui/icons-material/LanRounded';

export default function EditMe() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user, isLoggedIn } = useAuth();
  const { subdomain, isMainView } = useSubdomain();
  const username = searchParams.get('username') || user?.username;

  const isViewingOwnProfile = isMainView
    ? user?.username === username
    : user?.username === subdomain;

  useEffect(() => {
    if (!isLoggedIn || !isViewingOwnProfile) {
      navigate('/'); // Redirect if not authenticated or not the profile owner
    }
  }, [isLoggedIn, isViewingOwnProfile, navigate]);

  const [modalType, setModalType] = useState(null);

  const handleOpenModal = (type) => setModalType(type);
  const handleCloseModal = () => setModalType(null);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        minHeight: '100vh',
        px: 2,
        pt: 4,
        backgroundColor: 'background.default',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 500,
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          bgcolor: 'background.paper',
        }}
      >
        
        {/* Avatar and Username */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          
          <Avatar
            src="/path/to/profile-photo.jpg"
            alt="User Profile"
            sx={{
              width: 80,
              height: 80,
              marginRight: 2,
              bgcolor: 'primary.main',
              fontSize: '2rem',
            }}
          >
            {username?.[0]?.toUpperCase() || 'U'}
          </Avatar>
          
        </Box>
        
        <Typography variant="h4" color="primary" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
          {username}
        </Typography>
        
        <Divider sx={{ my: 3 }} />
{/* 🔹 Action Grid (Same Style, Improved Icon Styling) */}
<Grid2 container spacing={1.3}>
  {[
    { text: '+ Add New', action: 'faceScan', color: 'grey' },
    { text: '.me', color: 'white' },
    { text: 'FaceScan', icon: <FaceRetouchingNaturalRoundedIcon />, action: 'faceScan', color: '#FF3B30' },
    { text: 'Wallets', icon: <AccountBalanceWalletRoundedIcon />, action: 'walletOptions', color: '#5856D6'  },
        { text: 'Friends', icon: <PeopleAltRoundedIcon />, action: 'createWallet', color: '#FFD700' },
    { text: 'Groups', icon: <GroupsRoundedIcon />, action: 'createWallet', color: '#34C759' },
    { text: 'Social Medias', icon: <PublicRoundedIcon />, action: 'createWallet', color: '#007AFF' },
    { text: 'Devices', icon: <DevicesRoundedIcon />, action: 'createWallet', color: '#FF9500' },
    { text: 'dApps', icon: <LanRoundedIcon />, action: 'createWallet', color: '#5E5CE6' },
  ].map((item, index) => (
    <Grid2 xs={6} key={index}>
      
      <Box
        sx={{
          p: 1.5,
          border: 2,
          borderColor: item.color,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          cursor: 'pointer',
          transition: 'border-color 0.2s ease-in-out',
          '&:hover': {
            borderColor: item.color,
            opacity: 0.8,
          },
        }}
        onClick={() => handleOpenModal(item.action)}
      >
        {item.icon && (
          <Box
            sx={{
              fontSize: 28,
              color: 'inherit',
              display: 'flex',
              alignItems: 'center',
              mr: 1,
            }}
          >
            {item.icon}
          </Box>
        )}
        <Typography variant="body1" sx={{ color: 'inherit', fontSize: '.98rem' }}>
          {item.text}
        </Typography>
      </Box>
    </Grid2>
  ))}
</Grid2>

        <Divider sx={{ my: 3 }} />

      </Box>

      {/* 🔹 Modal Handling */}
      <Modal open={modalType === 'faceScan'} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <FaceScan open={modalType === 'faceScan'} onClose={handleCloseModal} />
        </Box>
      </Modal>

     {/* 🔹 Modal for Wallet Options */}
<Modal open={modalType === 'walletOptions'} onClose={handleCloseModal}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
    }}
  >
    <WalletOptions onClose={handleCloseModal} />
  </Box>
</Modal>
    </Box>
  );
}