// src/utils/useSubdomain.js
// Utility hook to determine subdomain and whether the user is on the main view
export const useSubdomain = () => {
  //console.log("VITE_BASE_DOMAIN:", import.meta.env.VITE_BASE_DOMAIN);
  // Determinar si estamos en entorno local o en producción
  const hostname = window.location.hostname;
  const isLocal = hostname.endsWith("lvh.me") || hostname.includes("localhost");
  // Determinar el dominio base correctamente
  const baseDomain =
    import.meta.env.VITE_BASE_DOMAIN ||
    (isLocal ? "lvh.me" : "cleaker.me");
  // Extraer el subdominio correctamente
  const subdomain = hostname.replace(`.${baseDomain}`, '');
  // Verificar si es la vista principal
  const isMainView = hostname === baseDomain || hostname === "www." + baseDomain;
  return { subdomain: isMainView ? null : subdomain, isMainView };
};