import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";

export default function MenuContent({ setActiveSection }) {
  const mainListItems = [
    { text: "Home", icon: <HomeRoundedIcon />, section: "home" },
    { text: "Contacts", icon: <PeopleRoundedIcon />, section: "groups" },
    { text: "+ Add", section: "add" },
  ];

  const secondaryListItems = [
    { text: "Social Medias", icon: <PublicRoundedIcon />, section: "social" },
    { text: "Tokens", icon: <SettingsRoundedIcon />, section: "tokens" },
    { text: "Feedback", icon: <HelpRoundedIcon />, section: "feedback" },
  ];

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton onClick={() => setActiveSection(item.section)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton onClick={() => setActiveSection(item.section)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}