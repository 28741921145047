import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Avatar,
  useTheme,
} from "@mui/material";
import QRCode from "react-qr-code"; // ✅ Import QR Code Component
import Wallet from "./Wallet";

export default function WalletsAvailable({ username }) {
  const theme = useTheme();
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [visibleWallets, setVisibleWallets] = useState(10); // ✅ Controls how many wallets are displayed initially
  const observer = useRef(); // ✅ For infinite scroll tracking

  useEffect(() => {
    function fetchWallets() {
      setLoading(true);
      try {
        const storedWallets = localStorage.getItem(`wallets_${username}`);
        if (storedWallets) {
          setWallets(JSON.parse(storedWallets));
        } else {
          setWallets([]);
        }
      } catch (error) {
        console.error("Failed to fetch wallets from localStorage:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchWallets();
  }, [username]);

  const handleOpenWallet = (wallet) => {
    setSelectedWallet(wallet);
  };

  const getWalletColor = (type) => {
    switch (type) {
      case "Ethereum":
        return "#627EEA"; // 🔵 Ethereum Blue
      case "Bitcoin":
        return "#F7931A"; // 🟠 Bitcoin Orange
      case "Solana":
        return "#9945FF"; // 🟣 Solana Purple
      default:
        return "#5E5E5E"; // ⚫ Default Dark
    }
  };

  // ✅ Intersection Observer to load more wallets
  const lastWalletRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setVisibleWallets((prev) => prev + 10); // ✅ Load 10 more wallets when reaching bottom
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  if (selectedWallet) {
    return (
      <Box sx={{ p: 4, textAlign: "center" }}>
        <Wallet wallet={selectedWallet} />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, textAlign: "center", maxWidth: 450, mx: "auto", overflowY: "auto", maxHeight: "80vh" }}>
      <Typography variant="h5" fontWeight={600} gutterBottom>
        Your Wallets
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
            gap: 1.5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {wallets.length > 0 ? (
            wallets.slice(0, visibleWallets).map((wallet, index) => (
              <Paper
                key={wallet.address} // ✅ Unique key
                ref={index === visibleWallets - 1 ? lastWalletRef : null} // ✅ Attach ref to last wallet
                onClick={() => handleOpenWallet(wallet)}
                elevation={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "8px 12px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  border: `2px solid ${getWalletColor(wallet.type)}`,
                  transition: "0.2s ease-in-out",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                    boxShadow: `0px 4px 10px rgba(0,0,0,0.1)`,
                  },
                }}
              >
                {/* ✅ Wallet Emoji */}
                <Avatar
                  sx={{
                    width: 34,
                    height: 34,
                    fontSize: 20,
                    backgroundColor: "transparent",
                  }}
                >
                  {wallet.emoji || "💳"}
                </Avatar>

                {/* ✅ Wallet Info */}
                <Box sx={{ flex: 1, marginLeft: 1 }}>
                  <Typography variant="body1" fontWeight={600} fontSize="0.9rem">
                    {wallet.name || `Wallet ${index + 1}`}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666", fontSize: "0.75rem" }}>
                    {wallet.type || "Unknown Blockchain"}
                  </Typography>
                </Box>

                {/* ✅ QR Code for Wallet Address */}
                <Box sx={{ width: 32, height: 32 }}>
                  <QRCode
                    value={wallet.address || "No Address"}
                    size={32}
                    fgColor={getWalletColor(wallet.type)}
                    bgColor="transparent"
                  />
                </Box>
              </Paper>
            ))
          ) : (
            <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
              No wallets found.
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}