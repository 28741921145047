import React from 'react';
import { Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import SideMenu from '../SideMenu/SideMenu';
import RightMenu from '../AppBar/RightMenu';
import WalletsAvailable from '../Wallet/WalletsAvailable'; // ✅ Import WalletsAvailable

export default function Layout({ children, activeSection }) {
  const isWide = useMediaQuery('(min-width:987px)');
  const isIntermediate = useMediaQuery('(min-width:610px) and (max-width:986px)');
  const isSmall = useMediaQuery('(max-width:609px)');

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: isWide ? '240px 1fr 240px' 
                                   : isIntermediate ? '240px 1fr' 
                                   : '1fr',
        height: '100vh',
      }}
    >
      {/* Sidebar */}
      {(isWide || isIntermediate) && (
        <Box
          sx={{
            backgroundColor: 'background.default',
            borderRight: '1px solid',
            borderColor: 'divider',
            overflowY: 'auto',
          }}
        >
          <SideMenu />
        </Box>
      )}

      {/* Main Content */}
      <Box sx={{ overflowY: 'auto', position: 'relative' }}>
        <CssBaseline />
        <Toolbar />
        <Box sx={{ p: 2 }}>
          {/* ✅ Dynamically render WalletsAvailable when activeSection is 'wallets' */}
          {activeSection === 'wallets' ? <WalletsAvailable /> : children}
        </Box>
      </Box>

      {/* Right Placeholder */}
      {isWide && (
        <Box
          sx={{
            backgroundColor: 'background.default',
            borderLeft: '1px solid',
            borderColor: 'divider',
          }}
        >
          <RightMenu />
        </Box>
      )}
    </Box>
  );
}