import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Container,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Chip,
  IconButton,
} from "@mui/material";
import { ethers } from "ethers";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmojiPicker from "../AppBar/EmojiSelector"; // Ensure this is an emoji selector component

// Define Blockchain Options
const blockchainOptions = [
  { name: "Ethereum", icon: "/icons/ethereum-blockchain.png" },
  { name: "Bitcoin", icon: "/icons/bitcoin-blockchain.png" },
  { name: "Dogecoin", icon: "/icons/dogecoin-blockchain.png" },
  { name: "XRP", icon: "/icons/xrp-blockchain1.png" },
  { name: "Stellar Lumens", icon: "/icons/stellar-blockchain.svg" },
];

export default function CreateWallet({ onClose, username }) {
  const [wallet, setWallet] = useState(null);
  const [walletName, setWalletName] = useState("Main Wallet");
  const [selectedEmoji, setSelectedEmoji] = useState("💰");
  const [loading, setLoading] = useState(false);
  const [blockchain, setBlockchain] = useState(blockchainOptions[0].name);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSeed, setShowSeed] = useState(false);

  // Toggle function for showing/hiding seed phrase
  const toggleSeedVisibility = () => setShowSeed((prev) => !prev);

  const handleGenerateWallet = () => {
    if (!selectedEmoji) {
      alert("Please select an emoji for your wallet!");
      return;
    }

    setLoading(true);
    setTimeout(() => {
      const newWallet = ethers.Wallet.createRandom();
      const storedWallets = localStorage.getItem(`wallets_${username}`);
      let wallets = storedWallets ? JSON.parse(storedWallets) : [];
      const walletData = {
        address: newWallet.address,
        privateKey: newWallet.privateKey,
        mnemonic: newWallet.mnemonic.phrase,
        name: walletName,
        emoji: selectedEmoji,
        blockchain, // Store blockchain name
      };
      wallets.push(walletData);
      localStorage.setItem(`wallets_${username}`, JSON.stringify(wallets));
      setWallet(walletData);
      setLoading(false);
    }, 2000);
  };

  // Copy mnemonic to clipboard
  const handleCopyMnemonic = async () => {
    if (!wallet?.mnemonic) {
      alert("No mnemonic available.");
      return;
    }
  
    try {
      // ✅ Modern Clipboard API (HTTPS Required)
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(wallet.mnemonic);
        alert("Mnemonic copied to clipboard!");
        return;
      }
  
      // ✅ Fallback for HTTP & Older Browsers
      const textarea = document.createElement("textarea");
      textarea.value = wallet.mnemonic;
      textarea.style.position = "fixed"; // Prevent scrolling to the textarea
      textarea.style.opacity = "0"; // Make it invisible
      document.body.appendChild(textarea);
      textarea.select();
  
      const success = document.execCommand("copy");
      document.body.removeChild(textarea);
  
      if (success) {
        alert("Mnemonic copied to clipboard! (Fallback)");
      } else {
        throw new Error("Clipboard copy command failed.");
      }
    } catch (err) {
      console.error("Clipboard copy failed:", err);
      alert("Failed to copy mnemonic. Please copy manually.");
    }
  };

  // Share wallet details
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: "My Wallet Details",
        text: `Wallet: ${wallet.name}\nAddress: ${wallet.address}`,
      });
    } else {
      alert("Sharing not supported on this device.");
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ textAlign: "center", p: 3 }}>
      {/* ✅ Wallet Loading Animation */}
      {loading && <CircularProgress size={50} sx={{ my: 2 }} />}
      {!loading && !wallet && (
        <>
          {/* ✅ Show Selected Emoji - Click to Change */}
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 0.1 }}>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ fontSize: "7rem" }}>
              {selectedEmoji}
            </IconButton>
          </Box>

          {/* ✅ Popover for Emoji Selection */}
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Box sx={{ p: 3 }}>
              <EmojiPicker onSelect={(emoji) => {
                setSelectedEmoji(emoji);
                setAnchorEl(null);
              }} />
            </Box>
          </Popover>

          {/* ✅ Blockchain Selection */}
          <FormControl fullWidth sx={{ mb: 1 }}>
            <InputLabel>Blockchain</InputLabel>
            <Select
              value={blockchain}
              onChange={(e) => setBlockchain(e.target.value)}
              sx={{ height: "56px", display: "flex", alignItems: "center" }}
              renderValue={(selected) => {
                const selectedBlockchain = blockchainOptions.find(b => b.name === selected);
                return (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={selectedBlockchain.icon} alt={selected} width="24" height="24" style={{ marginRight: 8 }} />
                    {selected}
                  </Box>
                );
              }}
            >
              {blockchainOptions.map((chain) => (
                <MenuItem key={chain.name} value={chain.name}>
                  <ListItemIcon>
                    <img src={chain.icon} alt={chain.name} width="24" height="24" />
                  </ListItemIcon>
                  <ListItemText primary={chain.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* ✅ Name the Wallet */}
          <TextField
            fullWidth
            label="Wallet Name"
            value={walletName}
            onChange={(e) => setWalletName(e.target.value)}
            sx={{ mb: 1, mt: 1 }}
          />

          {/* ✅ Generate Wallet Button */}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" color="primary" onClick={handleGenerateWallet} sx={{ mt: 1, fontWeight: "bold", fontSize: "1rem" }}>
              Add New Wallet
            </Button>
          </Box>
        </>
      )}

      {/* ✅ Show Wallet Info after Generation */}
      {wallet && (
        <Box sx={{ mt: 3, textAlign: "center" }}>
          {/* ✅ Blockchain Icon & Emoji */}
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
            <Typography variant="h6" sx={{ fontSize: "5rem" }}>{wallet.emoji}</Typography>
            <img src={blockchainOptions.find(b => b.name === wallet.blockchain)?.icon} alt={wallet.blockchain} width="48" height="48" />
          </Box>

          {/* ✅ Wallet Name */}
          <Typography variant="body1" fontWeight="bold" sx={{ mt: 1 }}>
            {wallet.name}
          </Typography>

          {/* ✅ Wallet Address */}
          <Typography variant="body1" sx={{
            mt: 1, p: 1, borderRadius: 2, wordBreak: "break-all",
            overflow: "hidden", textOverflow: "ellipsis",
            fontSize: "0.9rem", fontFamily: "monospace"
          }}>
            {wallet.address}
          </Typography>

          {/* ✅ Seed Phrase as Chips */}
          <Box sx={{ mt: 2, width: "100%", textAlign: "center" }}>
            <Box sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 1, width: "100%", justifyContent: "center"
            }}>
              {wallet?.mnemonic?.split(" ").map((word, index) => (
                <Chip key={index} label={showSeed ? word : "••••••"} sx={{
                  fontSize: "0.9rem", fontWeight: "bold",
                  border: "2px solid #007AFF", 
                  borderRadius: 2
                }} />
              ))}
            </Box>

            {/* ✅ Eye, Copy, and Share Buttons */}
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center", gap: 2 }}>
              <IconButton onClick={toggleSeedVisibility}>{showSeed ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
              <IconButton onClick={handleCopyMnemonic}><ContentCopyIcon /></IconButton>
              <IconButton onClick={handleShare}><ShareIcon /></IconButton>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
}