import React, { useState } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import InputRoundedIcon from '@mui/icons-material/InputRounded';
import OutputRoundedIcon from '@mui/icons-material/OutputRounded';
import WalletsAvailable from './WalletsAvailable'; // ✅ Import WalletsAvailable
import CreateWallet from './CreateWallet'; // ✅ Import CreateWallet

const WalletOptions = ({ onSelect, username }) => {
  const [viewingWallets, setViewingWallets] = useState(false);
  const [creatingWallet, setCreatingWallet] = useState(false);

  // Show WalletsAvailable when "Wallets" is clicked
  if (viewingWallets) {
    return (
      <Box sx={{ width: '100%', textAlign: 'center', p: 2 }}>
  
<Button
        onClick={() => setViewingWallets(false)}
        variant="outlined"
        sx={{
          position: "absolute",
          top: 8,
          left: 8,
          zIndex: 10, // Asegura que esté por encima de otros elementos si es necesario
        }}
      >
          ← Menu
        </Button>
        <WalletsAvailable username={username} />
      </Box>
    );
  }

// Show CreateWallet when "Create Wallet" is clicked
if (creatingWallet) {
  return (
    <Box sx={{ width: '100%', textAlign: 'center', p: 2, position: 'relative' }}>
      <Button
        onClick={() => setCreatingWallet(false)}
        variant="outlined"
        sx={{
          position: "absolute",
          top: 1,
          left: 1,
          zIndex: 10, // Asegura que esté por encima de otros elementos si es necesario
        }}
      >
        ← Menu
      </Button>
      <CreateWallet
        onClose={() => setCreatingWallet(false)}
        cleakerSalt="some-cleaker-salt"
        userId="some-user-id"
        username={username}
      />
    </Box>
  );
}

  const options = [
    { text: 'Wallets', icon: <AccountBalanceWalletRoundedIcon />, action: () => setViewingWallets(true), color: '#5856D6' },
    { text: 'Create Wallet', icon: <AddCircleRoundedIcon />, action: () => setCreatingWallet(true), color: '#007AFF' },
    { text: 'Import Wallet', icon: <InputRoundedIcon />, action: () => onSelect('importWallet'), color: '#34C759' },
    { text: 'Export Wallet', icon: <OutputRoundedIcon />, action: () => onSelect('exportWallet') },
  ];

  return (
    <Box sx={{ width: '100%', textAlign: 'center', p: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Wallet Options</Typography>
      <Grid container spacing={1.5}>
        {options.map((option, index) => (
          <Grid item xs={6} key={index}>
            <Box
              sx={{
                p: 1.5,
                border: 2,
                borderColor: option.color,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                cursor: 'pointer',
                transition: 'border-color 0.2s ease-in-out',
                '&:hover': {
                  borderColor: option.color,
                  opacity: 0.8,
                },
              }}
              onClick={option.action} // ✅ Clicking "Create Wallet" updates state
            >
              <Box
                sx={{
                  fontSize: 28,
                  color: 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                  mr: 1,
                }}
              >
                {option.icon}
              </Box>
              <Typography variant="body1" sx={{ color: 'inherit', fontSize: '.98rem' }}>
                {option.text}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WalletOptions;