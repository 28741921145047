import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const emojiPages = [
  ['😀', '😎', '🔥', '💡', '🌟', '🎉', '🚀', '❤️', '🍀', '🎵', '📚', '🖥️', '🛠️', '☀️', '🌙'],
  ['🌈', '❄️', '⚡', '🦄', '🍎', '🍕', '🐱', '🐶', '🎂', '🍇', '🌍', '🏔️', '🏖️', '🚗', '✈️'],
  ['🏆', '⚽', '🎹', '🎸', '🎮', '📷', '📱', '💻', '🔑', '🖊️', '📎', '📔', '🗂️', '📦', '🖼️'],
];
const EmojiSelector = ({ onSelect }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const handleNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % emojiPages.length);
  };
  const handleSelect = (emoji) => {
    onSelect(emoji); // ✅ Only update the parent component
  };
  return (
    <Box sx={{ textAlign: 'center' }}>
      {/* ✅ Emoji Grid - No extra rendering */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gap: '4px',
          justifyItems: 'center',
        }}
      >
        {emojiPages[currentPage].map((emoji, index) => (
          <IconButton
            key={index}
            onClick={() => handleSelect(emoji)}
            sx={{
              fontSize: '24px',
              width: '44px',
              height: '44px',
              color: 'text.primary',
              '&:hover': {
                transform: 'scale(1.2)',
                color: 'primary.main',
              },
            }}
          >
            {emoji}
          </IconButton>
        ))}
      </Box>

      {/* ✅ Navigation Button */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
        <Typography variant="body2" color="text.secondary" sx={{ mr: 0.5, fontSize: '0.8rem' }}>
          More Emojis
        </Typography>
        <IconButton
          onClick={handleNextPage}
          sx={{
            padding: 0.5,
            color: 'text.primary',
            '&:hover': {
              color: 'primary.main',
            },
          }}
        >
          <ArrowForwardIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default EmojiSelector;