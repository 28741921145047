//cleaker.me/client/src/App.jsx
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuth } from './context/AuthContext'; 
import { useSubdomain } from './utils/useSubdomain'; 
import Layout from './components/Layout/Layout'; 
import CleakerAppBar from './components/AppBar/CleakerAppBar'; 
import LoginPage from './pages/render/LoginPage'; 
import Home from './pages/Home'; 
import UserProfile from './pages/render/UserProfile'; 
import UserAttributePage from './pages/UserAttributePage'; 
import EmojiCursor from './components/EmojiCursor'; 
import EditMe from "./components/Me/EditMe"; 
import WalletsAvailable from "./components/Wallet/WalletsAvailable"; 
//import { Me } from "all.this";
import cleaker from 'cleaker/browser';
// Expose cleaker globally for console access
window.cleaker = cleaker;
console.log("Cleaker library is available in window.cleaker");
function App() {
  const { user, isLoggedIn, loading } = useAuth(); 
  const { subdomain, isMainView } = useSubdomain();
  const isViewingOwnProfile = !isMainView && user?.username === subdomain;  
  const [activeSection, setActiveSection] = useState("home");   // Add activeSection to control which view is displayed

  if (loading) return <div className="loader">Loading...</div>;
  return (
    <Router>
      {/* ✅ Pass setActiveSection to AppBar to allow updates */}
      <CleakerAppBar setActiveSection={setActiveSection} /> 

      {isLoggedIn ? (
        <Layout activeSection={activeSection}> {/* ✅ Pass activeSection */}
          <Routes>
            {isMainView && (
              <>
                {/* ✅ If "wallets" is selected, show WalletsAvailable instead of Home */}
                {activeSection === "wallets" ? (
                  <Route path="/" element={<WalletsAvailable />} />
                ) : (
                  <>
                    <Route path="/" element={<Home />} />
                    <Route path="/emoji" element={<EmojiCursor />} />
                    <Route path="/editMe" element={<EditMe />} /> 
                    <Route
                      path="/*"
                      element={<UserAttributePage profile={user?.username} isViewingOwnProfile />}
                    />
                  </>
                )}
              </>
            )}
            {!isMainView && (
              <>
                <Route path="/" element={<UserProfile profile={subdomain} />} />
                <Route
                  path="/*"
                  element={<UserAttributePage profile={subdomain} isViewingOwnProfile />}
                />
              </>
            )}
          </Routes>
        </Layout>
      ) : (
        <Routes>
          {isMainView ? (
            <>
              <Route path="/" element={<LoginPage />} />
              <Route
                path="/*"
                element={<UserAttributePage profile={subdomain} isViewingOwnProfile={false} />}
              />
            </>
          ) : (
            <>
              <Route path="/" element={<UserProfile profile={subdomain} />} />
              <Route
                path="/*"
                element={<UserAttributePage profile={subdomain} isViewingOwnProfile={false} />}
              />
            </>
          )}
        </Routes>
      )}
    </Router>
  );
}

export default App;