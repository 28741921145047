import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Divider,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsIcon from "@mui/icons-material/Settings"; // ✅ Added settings icon
import CryptoAddressQRCode from "./CryptoAddressQRCode";
import { ethers } from "ethers";
export default function Wallet({ wallet, onClose, onDelete }) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const [balance, setBalance] = useState(null);
  const [showQR, setShowQR] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // ✅ State for settings menu

  useEffect(() => {
    async function fetchBalance() {
      try {
        const provider = new ethers.providers.JsonRpcProvider(
          "https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID"
        );
        const walletBalance = await provider.getBalance(wallet.address);
        setBalance(ethers.utils.formatEther(walletBalance));
      } catch (error) {
        console.error("Failed to fetch balance:", error);
        setBalance("N/A");
      }
    }

    fetchBalance();
  }, [wallet.address]);

  // ✅ Copy wallet address
  const handleCopyAddress = async () => {
    try {
      await navigator.clipboard.writeText(wallet.address);
      alert("Wallet address copied!");
    } catch (err) {
      console.error("Clipboard copy failed:", err);
      alert("Failed to copy wallet address.");
    }
  };

  // ✅ Open & Close Settings Menu
  const handleSettingsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  // ✅ Export Private Key
  const handleExportPrivateKey = () => {
    alert(`Private Key: ${wallet.privateKey}`);
    handleSettingsClose();
  };

  // ✅ Delete Wallet (Confirmation)
  const handleDeleteWallet = () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this wallet?");
    if (confirmDelete) {
      onDelete(wallet.address);
    }
    handleSettingsClose();
  };

  // ✅ Get background colors based on theme
  const walletBackground = isDarkMode ? "#1a1a1a" : "#e3e6e8";

  return (
    <Card
      sx={{
        maxWidth: 340,
        mx: "auto",
        borderRadius: 2,
        backgroundColor: walletBackground,
        color: isDarkMode ? "#E0E0E0" : "#333",
        boxShadow: isDarkMode
          ? "0px 4px 15px rgba(0, 0, 0, 0.3)"
          : "0px 4px 15px rgba(0, 0, 0, 0.1)",
        p: 2,
        position: "relative",
      }}
    >
      <CardContent sx={{ p: 2, textAlign: "center" }}>
        {/* ✅ Back Button */}
        <IconButton onClick={onClose} sx={{ position: "absolute", top: 10, left: 10, color: isDarkMode ? "#E0E0E0" : "#333" }}>
          <ArrowBackIcon />
        </IconButton>

        {/* ✅ Settings Button */}
        <IconButton
          onClick={handleSettingsOpen}
          sx={{ position: "absolute", top: 10, right: 10, color: isDarkMode ? "#E0E0E0" : "#333" }}
        >
          <SettingsIcon />
        </IconButton>

        {/* ✅ Settings Menu */}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleSettingsClose}>
          <MenuItem onClick={handleExportPrivateKey}>Export Private Key</MenuItem>
          <MenuItem onClick={handleDeleteWallet} sx={{ color: "red" }}>
            Delete Wallet
          </MenuItem>
        </Menu>

        {/* ✅ Wallet Emoji & Name */}
        <Typography variant="h3" sx={{ fontSize: "3rem", mb: 1 }}>
          {wallet.emoji || "💰"}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: isDarkMode ? "#B0B0B0" : "#555", fontSize: "1rem" }}>
          {wallet.name || "Unnamed Wallet"}
        </Typography>

        <Divider sx={{ my: 2, backgroundColor: isDarkMode ? "#333" : "#DDD" }} />

        {/* ✅ Wallet Balance */}
        <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1.2rem", color: isDarkMode ? "#00E676" : "#007AFF", mb: 1 }}>
          {balance !== null ? `${balance} ETH` : "Loading..."}
        </Typography>

        {/* ✅ Wallet Address */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: isDarkMode ? "#222" : "#ffffff",
            p: 1.5,
            borderRadius: 2,
            border: `1px solid ${isDarkMode ? "#2D2D3A" : "#CCC"}`,
            mb: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontFamily: "monospace",
              fontSize: "0.85rem",
              color: isDarkMode ? "#E0E0E0" : "#333",
              wordBreak: "break-word",
              flexGrow: 1,
              textAlign: "left",
              px: 1,
            }}
          >
            {wallet.address.slice(0, 6) + "..." + wallet.address.slice(-6)}
          </Typography>
          <Tooltip title="Copy Address">
            <IconButton size="small" sx={{ color: isDarkMode ? "#00E676" : "#007AFF" }} onClick={handleCopyAddress}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* ✅ QR Code Toggle */}
        <Box sx={{ my: 2, textAlign: "center" }}>
          <Tooltip title={showQR ? "Hide QR Code" : "Show QR Code"}>
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderColor: isDarkMode ? "#00E676" : "#007AFF",
                color: isDarkMode ? "#00E676" : "#007AFF",
                mb: 1,
              }}
              onClick={() => setShowQR(!showQR)}
            >
              {showQR ? "Hide QR Code" : "Show QR Code"}
            </Button>
          </Tooltip>

          {showQR && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: isDarkMode ? "#222" : "#F0F0F0",
                padding: 1.5,
                borderRadius: 2,
                border: `1px solid ${isDarkMode ? "#2D2D3A" : "#CCC"}`,
                maxWidth: "fit-content",
                mx: "auto",
              }}
            >
              <CryptoAddressQRCode address={wallet.address} size={140} />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}