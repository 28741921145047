import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded'; // Security Icon
import { Box } from '@mui/material';

export default function CardAlert() {
  return (
    <Card variant="outlined" sx={{ m: 1.5, p: 2, borderRadius: 2, boxShadow: 1 }}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={1.5}>
          <VerifiedUserRoundedIcon fontSize="small" sx={{ color: 'primary.main', mr: 1 }} />
          <Typography sx={{ fontWeight: 600 }}>
            Identity Verification Needed
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
          Confirm your Cleaker identity to ensure secure access to your decentralized profile.
        </Typography>
        <Button variant="contained" color="primary" size="small" fullWidth>
          Verify Now
        </Button>
      </CardContent>
    </Card>
  );
}